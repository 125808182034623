import React, {useState} from 'react';
import {SectionTitle, SectionText, ActionButton} from '../../atoms';
import {Container} from '../../global';
import {
  FormWrapper,
  FormFlex,
  FormColumn,
  FormTitle,
  FormContainer,
  FormInput,
  FormTextarea,
  FormSubmit,
  FlexLine,
  SocialIcon,
  FormAgree,
} from './styles';
import IconMail from '../../../images/mail.svg';
import IconFB from '../../../images/facebook.svg';
import IconYT from '../../../images/youtube.svg';
import IconInstagram from '../../../images/instagram.svg';
import emailjs from 'emailjs-com';

const User = 'user_kVOGuM96FHsDB1OK1GkVK';
const Token = '206b8a8dc33f19470bbca356c6c11e9e';

const Form = () => {
  const [isAgree, setAgree] = useState(false);
  const [formValues, setFormValues] = useState({
    name: '',
    mail: '',
    subject: '',
    message: '',
  });
  const onInputChange = (e) => {
    setFormValues({...formValues, [e.target.name]: e.target.value});
  };

  const onSubmit = (e) => {
    e.preventDefault();
    emailjs
      .send(
        'service_7b834zj',
        'template_c6q616g',
        {
          from_email: formValues.mail,
          from_name: formValues.name,
          from_topic: formValues.subject,
          message: formValues.message,
        },
        User,
      )
      .then((result) => {
        setFormValues({name: '', mail: '', subject: '', message: ''});
      });
  };
  return (
    <FormWrapper id="contact">
      <Container>
        <FormFlex>
          <FormColumn>
            <FormTitle>Contact form</FormTitle>
            <FormContainer>
              <form onSubmit={onSubmit}>
                <FormInput
                  name="name"
                  type="text"
                  placeholder="Name"
                  onChange={onInputChange}
                  value={formValues.name}
                />
                <FormInput
                  name="mail"
                  type="email"
                  placeholder="E-mail"
                  onChange={onInputChange}
                  value={formValues.mail}
                />
                <FormInput
                  name="subject"
                  type="text"
                  placeholder="Subject"
                  onChange={onInputChange}
                  value={formValues.subject}
                />
                <FormTextarea
                  name="message"
                  placeholder="Message"
                  onChange={onInputChange}
                  value={formValues.message}
                />
                <FormAgree>
                  <div>
                    <input
                      type="checkbox"
                      name="agree"
                      onChange={(e) => setAgree(e.target.checked)}
                      checked={isAgree}
                    />
                  </div>
                  <p>
                    <a
                      href="https://mckmilanowek.pl/kontakt-2/polityka-prywatnosci/"
                      target="_blank">
                      I have read the information about the administrator and
                      about the processing of my personal data.
                    </a>
                    I consent to the processing of this data.
                  </p>
                </FormAgree>
                <FormSubmit
                  style={{opacity: !isAgree && '0.6'}}
                  type="submit"
                  onClick={(e) => !isAgree && e.preventDefault()}>
                  SEND
                </FormSubmit>
              </form>
            </FormContainer>
          </FormColumn>
          <FormColumn>
            <SectionTitle>CONTACT</SectionTitle>
            <SectionText margin={'1rem 0 1rem'}>
              &emsp;&emsp;Do you want to share your thoughts on your experience
              of the museum? Do you need the materials displayed on the website
              in a better quality for your school work or thesis? Or maybe you
              have something else to share? Everyone interested in contacting
              the Virtual Museum of Jan Szczepkowski is more than welcome to use
              the form placed below. We are going to reply to every single
              message you will send!
            </SectionText>
            {/* <ActionButton color="blue">Czytaj dalej</ActionButton> */}

            <FlexLine style={{marginTop: '1rem'}}>
              <SocialIcon src={IconMail} />
              <SectionText>muzeum.szczepkowskiego@gmail.com</SectionText>
            </FlexLine>
            <FlexLine href="https://www.facebook.com/muzeumszczepkowskiego">
              <SocialIcon src={IconFB} />
              <SectionText>facebook.com/muzeumszczepkowskiego</SectionText>
            </FlexLine>
            <FlexLine href="https://www.instagram.com/muzeum.szczepkowskiego">
              <SocialIcon src={IconInstagram} />
              <SectionText>instagram.com/muzeum.szczepkowskiego</SectionText>
            </FlexLine>
            <FlexLine href="https://www.youtube.com/channel/UCHfT0NabOoNPx2DR-BmsD8Q">
              <SocialIcon src={IconYT} />
              <SectionText>youtube.com/muzeumszczepkowskiego</SectionText>
            </FlexLine>
          </FormColumn>
        </FormFlex>
      </Container>
    </FormWrapper>
  );
};

export default Form;

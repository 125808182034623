import React from 'react';
import {
  SlideWrapper,
  ImageWrapper,
  SlidePrimeText,
  SlideSecondaryTextWrapper,
  SlideSecondaryLogo,
  Dots,
  text,
} from './styles';
import slide1 from '../../../images/slider_1a.jpg';
import slide2 from '../../../images/slider_2a.jpg';
import LogoMobileSVG from '../../../images/logo_mobile.svg';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const SlidePrime = () => (
  <SlideWrapper>
    <SlidePrimeText>
      <p>120 sculptures</p>
      <p>60 3D models</p>
      <p>100 photographs</p>
    </SlidePrimeText>
    <ImageWrapper src={slide2} />
  </SlideWrapper>
);

const SlideSecondary = () => (
  <SlideWrapper>
    <SlideSecondaryTextWrapper>
      <SlideSecondaryLogo src={LogoMobileSVG} />
    </SlideSecondaryTextWrapper>
    <ImageWrapper src={slide1} />
  </SlideWrapper>
);

const TitleSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    appendDots: (dots) => <Dots>{dots}</Dots>,
  };
  return (
    <Slider {...settings}>
      <SlideSecondary />
      <SlidePrime />
    </Slider>
  );
};

export default TitleSlider;

import React, {useEffect, useState} from 'react';
import SEO from '../../components/seo';
import TitleSliderEN from '../../components/desktop/TitleSlider/TitleSliderEN';
import TitleSliderMobileEN from '../../components/mobile/TitleSlider/TitleSliderEN';
import FormEN from '../../components/desktop/Form/FormEN';
import Grid from '../../components/desktop/Grid/Grid';
import Foto1 from '../../images/FOTO_1.png';
import Foto2 from '../../images/FOTO_2.png';
import Foto3 from '../../images/FOTO_3.png';

const IndexPage = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.innerWidth < 768 && setIsMobile(true);
    }
  }, []);

  return (
    <>
      <SEO title="Home" />
      {!isMobile ? <TitleSliderEN /> : <TitleSliderMobileEN />}
      <Grid
        backgroundColor={'#a3c0c0'}
        backgroundImage={Foto1}
        reverse={false}
        sectionTitle={'ARTIST'}
        buttonText="Read more"
        navigateTo="/en/artist-1"
        sectionText={`&emsp;&emsp;So here’s a thing: on a daily basis, while doing our errands we often unconsciously pass by the works of Jan Szczepkowski either in Cracow or Warsaw. In Cracow, you can find his reliefs of the four Evangelists on the vault of the St. Florian’s Church and Henryk Jordan’s statue. However, the reliefs on BGK, Sejm’s facade as well as the monuments of Moniuszko and Bogusławski are to be seen in Warsaw. Yet, his most famous work, Chapel of Nativity awarded in 1925, is actually in France! Not to mention his contribution to cultivating the memory of Marshal Józef Piłsudski. You just need to stop for a quick second and look around. Thus, this website is a marvelous starting point to get to know our extraordinary artist.`}
      />
      <Grid
        backgroundColor={'#e09087'}
        backgroundImage={Foto2}
        reverse={true}
        longTitle
        sectionTitle={'ABOUT THE MUSEUM'}
        buttonText="Read more"
        navigateTo="/en/about-1"
        sectionText={`&emsp;&emsp;To this day, the word “museum” is associated with many limitations, dust, devout silence and descriptions written on tiny pieces of paper, that we tend to overlook. At least, this is the case in many stationary museums. We are different though, as were limited only by our imagination in the largest exhibition space in the world called the Internet! You can visit us every day and at any time. The attractions include 120 digitized works of Jan Szczepkowski, of which 60 are 3D models, and over 100 archival photographs! Feel free to click anywhere you want, as no museum guard is going to stop you, nor do we! Get cozy, wear your most comfortable clothes or even pajamas and enjoy!`}
      />
      <Grid
        backgroundColor={'#e0c887'}
        backgroundImage={Foto3}
        reverse={false}
        sectionTitle={'THE ARTWORK'}
        buttonText="ARTWORKS"
        navigateTo="/en/arts"
        sectionText={`&emsp;&emsp;We have decided to divide the collected pieces of Szczepkowski’s legacy into four categories. The first one “Depiction of the figures” gathers the sculptures, figures and busts carved by the artist. The second one “Marshall Józef Piłsudski” brings together all the works dedicated to this particular character. The third one “Reliefs” shows Szczepkowski’s plaster board and wooden creations. The last one “Other works” collects the rest of the pieces that did not fit in previous categories, mainly including urban plans for Warsaw, medals and furniture that he designed.`}
      />
      <FormEN />
    </>
  );
};

export default IndexPage;
